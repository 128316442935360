.service-area {
    padding: 11px;
    background-color: #0094b2;
    text-align: center;
    font-size: 22px;
    margin: 14px;
    color: white;
    width: 330px;
    float: left;
}
.row{
	float: left;
	width:100%;
}